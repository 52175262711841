$primary-color: #548974;
$secondary-color: #bbd560;

$danger-color: #D32F2F;
$success-color: green;
$warning-color: orange;

$high-vrt: #7a0177;
$medium-vrt: #f768a1;
$low-vrt: #feebe2;

$link-color: #2980b9;

.text-primary {
  color: $primary-color;
}

.text-success {
  color: $success-color;
}

.text-danger {
  color: $danger-color;
}

.text-warning {
  color: $warning-color;
}

.is-mobile {
  display: none !important;
}


.text-link {
  color: $link-color;
  cursor: pointer;
}

.background-secondary {
  background: $secondary-color !important;
}

.background-secondary-with-opacity {
  background: rgba($secondary-color, 0.3) !important;  
}

.background-primary {
  background: $primary-color !important;
}


@mixin material-shadow($color: #000, $alpha1: 0.14, $alpha2: 0.12, $alpha3: 0.2) {
  -webkit-box-shadow: 0 2px 2px 1px rgba($color, $alpha1), 0 3px 1px -2px rgba($color, $alpha2), 0 1px 5px 0 rgba($color, $alpha3) !important;
  -moz-box-shadow: 0 2px 2px 1px rgba($color, $alpha1), 0 3px 1px -2px rgba($color, $alpha2), 0 1px 5px 0 rgba($color, $alpha3) !important;
  box-shadow: 0 2px 2px 1px rgba($color, $alpha1), 0 3px 1px -2px rgba($color, $alpha2), 0 1px 5px 0 rgba($color, $alpha3) !important;

}

@media (max-width: 768px) {
  .is-mobile {
    display: block !important;
  }

  .is-desktop {
    display: none !important;
  }
}