.account-container {

  &.billing-container-mobile {
    display: none;

    strong {
      font-size: 14px !important;
    }
  }

  .order-preview-sticky {
    margin-left: 16px;
    width: calc(100%);
    max-width: unset;
    padding-right: 24px !important;
    box-shadow: 0px 1px 6px 1px rgb(0 0 0 / 20%), 0px -2px 2px 0px rgb(0 0 0 / 14%), 0px -1px 5px 0px rgb(0 0 0 / 12%) !important;

  }

  .account-item-title {
    color: $primary-color;
    margin: 10px 0;
  }

  .account-item-box {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .billing-container {
    display: none !important;

    &.billing-container-mobile {
      display: block !important;
    }

    .order-preview-sticky {
      margin-left: -8px !important;
      width: calc(100% + 32px) !important;
      max-width: unset;
      padding-right: 24px !important;
    }
  }
}

@media (max-width: 600px) {
  .account-container {

      .order-preview-sticky {
        margin-left: 0px !important;
        width: calc(100% + 16px) !important;
        max-width: unset;
        padding-right: 16px !important;
      }
    
  }

}