html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.MuiSnackbar-root {
  top: 60px !important;
  z-index: 999999999999999999999 !important;
}

.service-worker-notification {
  z-index: 15;
  position:absolute;
  top: -80px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: #fff;
  background: rgba(#666, 1);
  font-weight: bold;
  height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  transition: .2s top linear;

  &.show {
    top: 0;
  }

  div {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    background: $primary-color;
    color: #fff;

    &:hover {
      background: $secondary-color;
    }
  }
}

.MuiButton-root {
  padding-top: 6px !important;
  svg {
    margin-bottom: 2px;
  }
}

.MuiDialogTitle-root, .MuiDialogActions-root {
  z-index: 10;
  box-shadow: 0px 0px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.MuiDialogActions-root {
  box-shadow: 0px 0px 1px -2px rgb(0 0 0 / 20%), 0px -2px 2px 0px rgb(0 0 0 / 14%), 0px -1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100000 !important;
}



.documents-table-mobile {
  display: none !important;
}
// .google-oauth-btn {
//   margin: 0;
//   margin-top: 30px;
//   padding: 1px;
//   width: 100% !important;
//   background-color: #4285f4;
//   border: none;
//   border-radius: 1px;
//   cursor: pointer;
//   box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
//   transition: .1s all linear;

//   &:hover {
//     box-shadow: 0 0 8px 0px #4285f4;
//   }

//   &:active {
//     background-color: darken(#4285f4, 10);
//   }

//   .abcRioButtonContentWrapper {
//     display: flex;
//   }

//   .abcRioButtonBlue {
//     background-color: #4285f4;
//     border: none;
//     color: #fff;
//   }

//   .abcRioButtonIcon {
//     margin: 0;

//     background-color: #fff;
//     border-radius: 1px;
//     width: 18px;
//     height: 18px;

//     margin-right: 24px;
//   }

//   .abcRioButtonContents {
//     color: #fff;
//   }

// }


.google-auth-button {
  width: 100%;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  opacity: 1;
  transition: .1s linear all;

  &>div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    opacity: .9;
  }

  &:active {
    background-color: rgb(238, 238, 238);
  }
}

#signInDiv {
  margin: 20px auto 0 auto;

  &>div {
    text-align: center;

    iframe {
      margin: 0 auto !important;
      // width: 100% !important;
    }
  }
}

.main-container {
  display: flex;
  height: 100%;
  width: 100%;

  .content-container {
    width: calc(100% - 40px);

    .header-container {
      position: sticky;
      top: 0;
      z-index: 3;
      height: 50px;
      width: 100%;
      background: #F2F2F2;
      @include material-shadow(rgb(75, 75, 75));

      .view-title {
        font-size: 18px;
      }

      .header-item {
        margin: 0 5px;
        cursor: pointer;
      
        &.item-icon {
          color: $primary-color;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    
    .routes-container {
      height: calc(100% - 50px);
      position: relative;

     

      .profile-container {
        height: 100%;
        overflow-y: auto;
      }

      .vrt-container {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 20px
      }

      .fieldbook-container {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 20px
      }

      .map-view-container {
        .toggle-datapanel {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: 0;
          background: #fff;
          width: 50px;
          z-index: 3;
          text-align: center;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          transition: .2s all linear;
          cursor: pointer;

          &.open {
            bottom: 250px;
          }

          &:hover {
            background: darken(#fff, 10);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {

  .documents-table-desktop {
    display: none !important;
  }

  .documents-table-mobile {
    display: flex !important;
  }

  .auth-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .links-container {
      height: 60px !important;

      a {
        line-height: 60px !important;
      }
    }
    .auth-logo {
      width: 110px !important;
      height: 50px !important;
    }
  }
}


@media (max-width: 600px) {
  .service-worker-notification {
    width: 100%;
  }
  .main-container {
    .content-container {
      .view-title {
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 599px) {
  .MuiSnackbar-root {
    // width: calc(100% - 60px);
    // left: 50px !important;
  }
}

@keyframes fromTop {
  from {
    top: -80px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}