.datapanel-container {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: -250px;
  height: 250px;
  width: 100%;
  background: #fff;
  // overflow-x: hidden;
  transition: .2s transform linear;

  .only-on-mobile {
    display: none;
  }
  .summary-redirect-button {
    min-height: unset;
    width: 35px !important;
    height: 35px !important;
    svg {
      font-size: 18px !important;
    }
  }

  .datepicker-filter {
    input {
      font-size: 13px !important;
      padding: 8.5px 3px;
    }
  }

  &.visible {
    transform: translateY(-250px)
  }

  .MuiTab-root {
    padding: 12px 6px;
    min-width: unset;
  }

  .MuiTabPanel-root {
    padding: 8px;
    h6 {
      text-align: center;
    }

    height: 185px;
  }
  .summary-container {
    
    .summary-parcel-item {
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
    }
    .MuiButton-root.details-button {
      // padding: 0 10px !important;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .summary-short-container {
      min-height: calc(100% - 50px);

      

      .MuiPaper-root {
        padding: 5px;

        td {
          padding: 1px 4px;
          font-size: 13px;
          white-space: nowrap;
        }

        svg {
          font-size: 1rem;
        }
      }

    }
  }

  .forecast-container {
    .alice-carousel {
      width: calc(100% - 50px);

      .alice-carousel__dots {
        display: none;
      }

      .alice-carousel__prev-btn {
        width: unset;
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
      }

      .alice-carousel__next-btn {
        width: unset;
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .daily-container {
      text-align: center;
      cursor: pointer;
      transition: .1s background linear;

      &:hover {
        background: rgba($secondary-color, .6);
      }
      

      .daily-box {
        display: flex;
        justify-content: center;
        align-items: center;

        &>div {
          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            margin-left: 5px;
          }
        }
      }

      .daily-day {
        text-align: center;
        padding: 3px 0 6px 0;
        font-weight: bold;
        color: $primary-color;
      }

      .daily-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        .higher {
          font-size: 28px;
          font-weight: bold;
          color: #333;
        }

        .lower {
          margin-top: 5px;
          opacity: .6;
          font-weight: bold;
          font-size: 16px;

        }
      }

      .daily-precipitation {
        color: #555;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
      }

      .daily-item {
        padding-bottom: 12px;
        font-size: 14px;
        color: #333;

        .daily-item-title {
          padding-bottom: 4px;
          opacity: .9;
        }

        .daily-item-value {
          font-weight: bold;
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  .datapanel-container {

  }
}

@media (max-width: 768px) {
  .datapanel-container {
    .only-on-desktop {
      display: none !important;
    }

    .only-on-mobile {
      display: flex;
    }
    .summary-parcel-details-container {
      width: 100%;
      
      .summary-parcel-item {
        max-width: 100% !important;
      }

    
    }
  }
}