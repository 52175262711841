.scroll-calendar-month-container {
  display: block;
  width: 100%;
  margin: 20px 0;

  .weekdays {
    display: block;
    list-style: none;

    width: 100%;
    padding: 0;

    li {
      display: list-item;
      text-transform: uppercase;
      float: left !important;
      text-align: center;
      width: 14.28%;
      padding: 7px 0;
      color: #999;
      margin: 5px 0;
      
      &.day {
        color: #555;
       &:last-child {margin-bottom:40px;}
      }

      &.active {
        border-radius: 20px;
        color: #fff;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        &:hover {
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 20px;
            background: rgba(#000, .1);
          }
        }
      }

      &.disabled { 
        // background: rgba(#000, .03);
      }
    }
  }
}