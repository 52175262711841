.PrivatePickersToolbar-dateTitleContainer {
	.PrivateDatePickerToolbar-penIcon {
		display: none;
	}
}

.vrt-container {

	.parcel-list-drawer-container {
		&>div.MuiPaper-root {
			overflow: hidden;
		}
	}	

	.prev-next {
		cursor: pointer;
		transition: .1s transform linear;

		&:hover {
			transform: scale(1.1);
		}

		&:active {
			transform: translateY(2px);
		}

		&.disabled {
			color: rgba(#000, .2) !important;
			cursor: not-allowed;

		}
	}

	.vrt-amount {
		line-height: 40px;
		width: 20px;
		height: 20px;
		border-radius: 6px;

		&.high { background: $high-vrt; }
		&.medium { background: $medium-vrt; }
		&.low { background: $low-vrt; }
	}
	
	.vrt-parcel-list-container {
		z-index: 1999;
		overflow-y: auto;
		max-width: 310px;
		height: 100vh;

		.vrt-parcel-list-header {
			background: $primary-color;

			color: #fff;
			padding: 15px 10px;

			svg {
				cursor: pointer;
			}
		}
	}
}
