.navigation-container {
  width: 40px !important;
  height: 100%;
  background: $primary-color;
  z-index: 4;

  @include material-shadow(rgb(75, 75, 75));

  .nav-link {
    display: block;
    height: 50px;
  }

  .nav-icon {
    width: 100%;
    padding: 13px 0;
    cursor: pointer;

    &.active {
      path {
        fill: $secondary-color;
      }
    }

    path {
      fill: #fff;
    }

    &:hover {
      path {
        fill: $secondary-color;
      }
    }
  }
}