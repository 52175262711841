.overlay-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  overflow: hidden;
  z-index: 100020;
  background-color: rgba(0, 0, 0, 0.5) !important;
  mix-blend-mode: hard-light !important;

  .spotlight {
    border-radius: 4px;
    position: fixed;
    background-color: gray !important;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s ease 0s;
    width: 115px;
    display: none;
    z-index: 100025125120136136136 !important;

    &.active {
      display: block !important;
    }
  }
  
}


.spotlight-content {
  z-index: 10002512512013613613 !important;
  position: fixed;
  background: #fff;
  padding: 15px;
  transform: translateY(-50%);
  border-radius: 5px;
  max-width: 250px;
  font-size: 14px;

  display: none;
    
    &.active {
      display: block;
    }
  &.left {
    &::after {
      z-index: 1001000251251201361361300;
      content: '';
      position: fixed;
      top: 50%;
      right: -7px;
      left: unset;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent; 
      border-left: 7px solid #fff;
      border-right: none;
      transform: translateY(-50%);
    }
  }

  &.top {
    transform: translate(-50%, -50%);
    &::after {
      z-index: 10002512512013613613;
      position: fixed;
      top: calc(100% - 1px);
      right: 50%;
      left: unset;
      border-top: 7px solid #fff;
      border-bottom: none; 
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      transform: translateX(50%);
    }
  }

  &::after {
    z-index: 10000;
    content: '';
    position: fixed;
    top: 50%;
    left: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right: 7px solid #fff;
    transform: translateY(-50%);
  } 
}