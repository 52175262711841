.fieldbook-container {
  .fieldbook-button-group {
    button {
      margin-right: 5px;
    }
  }

 

  .activity-item {
    cursor: pointer;
    transition: .1s transform linear;

    .MuiTimelineDot-root, .MuiChip-root {
      transition: .1s background-color linear;
    }

    &:hover{ 
      transform: scale(1.05);

      .MuiTimelineDot-root, .MuiChip-root {
        background-color: $secondary-color;
      }
    }
  }

  .field-item-card {
    position: relative;
    cursor: pointer;
    transition: .1s all linear;
    will-change: transform;

    &::after {
      content: '';
      background: rgba($primary-color, .6);
      opacity: 0;
      transition: .1s opacity linear;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      will-change: opacity;
    }

    &:active {
      transform: translateY(2px) scale(1.02)  !important;
    }

    &:hover {
      transform: scale(1.02);
      &::after {
        opacity: 1;
      }
    }
  }
}


.activity-view-container {
  .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
}

.job-orders-preview-container {
  .MuiInputLabel-root {
    color: rgba(#555, .7) !important;
  }
  .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: gray;
  }

  .job-field {
    .MuiInputLabel-root {
      color: $primary-color !important;
    }
    .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-color: $primary-color;
    }
  }
}

@media (max-width: 768px) {
  .fieldbook-container {
    .fieldbook-button-group {
      button {
        margin: 0 0 5px 0;
        width: 100%;
      }
    }
  }
}