.panel-container {
  width: 0;
  height: 100%;
  background: #fff;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  transition: .2s all linear;
  will-change: left;
  left: -300px;
  position: relative;


  &.opened {
    width: 300px;
    left: 0;
    // transform: translateX(0);
  }

 

  .datepicker-panel-container {
    input {font-size: 12px}

    .MuiSvgIcon-root {
      
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }

      border-radius: 100%;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
        background: rgba($primary-color, .3);
      }

      &:active {
        transform: scale(1.2) translateY(1px);
      }

      &.disabled {
        background: transparent;
        transform: scale(1) !important;
        cursor: not-allowed;
        color: rgba(#555, .6) !important;
      }
    }
  }

  table.layers-container {
    width: 100%;
  table-layout: fixed;
    .MuiSelect-select.MuiSelect-outlined {
      font-size: 14px;
    }
    td {

      &:first-child {
        h6 {
          overflow: hidden;
          text-overflow: ellipsis;
          // white-space: nowrap;
  
        }
        width: 90px;
      }

      &:last-child {
        // text-align: center;
        padding-left: 5px;
        width: auto !important;

      }

      padding: 10px 0;

      .MuiSlider-colorPrimary {
        margin-right: 10px;
        width: calc(100% - 10px)

      }

      .MuiAvatar-root {
        cursor: pointer;
        transition: .1s transform linear;
        opacity: .5;

        @include material-shadow;

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(1.05) translateY(2px);
        }

        &.active {
          opacity: 1;
          // transform: scale(1.05);
        }
      }

      .MuiChip-root {
        cursor: pointer;
        transition: .1s transform linear;

        @include material-shadow;

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(1.05) translateY(2px);
        }

        &.active {
          transform: scale(1.05);
        }
      }
      
    }
  }

  @include material-shadow;

  .panel-section-title {
    background: rgba(#000, .05);
    // color: #fff;
  }
}

@media (max-width: 600px) {
  .panel-container {
    position:absolute;
    
    width: 100%;

    left: -100%;

    &.opened {
      width: 100% !important;
      left: 0;
    }
  }
}