
  .search-location-input {
    .MuiOutlinedInput-root {
      padding-right: 6px !important;
    }
  }


.map-wrapper {
  height: 100%;
  width: calc(100%);
  position: relative;
  transition: .2s width linear;


  &.opened {
    width: calc(100% - 280px);
  }

  .legend-color {
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      border: 1px solid gray;
    }
  }

  .map {
    width: 100%;
    height: 100%;

    .ol-zoom {
      display: none;
    }

    .ol-attribution {
      button {
        background: $primary-color !important;
      }
    }
  }
}

.map-view-container {
  .toolbar-icon {
    font-size: 22px;
    margin-bottom: 2px;
  }
}



@media (max-width: 600px) {
  .map-wrapper {
    width: 100% !important;

  }
}